<template>
  <div class="side-ctrls-wrapper">
    <button class="side-ctrls-toggle btn" @click="isOnScreen = !isOnScreen">
      {{ isOnScreen ? ">>" : "&lt;&lt;" }}
    </button>

    <div class="side-ctrls" :class="toggleClass">
      <div class="ctrls-title">
        Filter lights
      </div>

      <div class="media-ctrls">
        <label>
          <small>Contains image </small>
          <input
            type="checkbox"
            v-model="filters.hasImage"
            @change="handleFilterToggle('hasImage')"
          />
        </label>

        <label>
          <small>Contains audio </small>
          <input
            type="checkbox"
            v-model="filters.hasAudio"
            @change="handleFilterToggle('hasAudio')"
          />
        </label>
      </div>

      <div class="tag-ctrls">
        <input
          class="text-input"
          type="text"
          placeholder="Filter by tag"
          v-model="tag"
        />

        <div class="tag-ctrls-btns">
          <button class="btn" @click="handleTagAdd">Add</button>
          <button class="btn" @click="handleTagClearAll">Clear All</button>
        </div>

        <div class="active-tags">
          <div
            class="tag"
            v-for="(tag, i) in activeTags"
            :key="i"
            @click="handleTagClear(tag)"
          >
            {{ tag }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideControls",
  computed: {
    ...mapGetters("sideControls", ["activeTags"]),
    toggleClass() {
      return this.isOnScreen ? "side-ctrls-open" : "side-ctrls-closed";
    },
  },
  data() {
    return {
      tag: "",
      filters: {
        hasImage: false,
        hasAudio: false,
      },
      isOnScreen: false,
    };
  },
  methods: {
    handleTagAdd() {
      if (!this.tag) return;
      if (this.activeTags.includes(this.tag)) return;
      this.$store.dispatch("sideControls/addTagFilter", this.tag.trim());
      this.tag = "";
      this.closeOnMobile();
    },
    handleTagClear(tag) {
      this.$store.dispatch("sideControls/removeTagFilter", tag);
      this.closeOnMobile();
    },
    handleTagClearAll() {
      if (!this.activeTags.length) return;
      this.$store.dispatch("sideControls/tagsClearAll");
      this.closeOnMobile();
    },
    handleFilterToggle(filter) {
      this.$store.dispatch("sideControls/toggleFilter", filter);
      this.closeOnMobile();
    },
    closeOnMobile() {
      if (screen.width < 768) this.isOnScreen = false;
    },
  },
};
</script>

<style></style>
