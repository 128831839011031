<template>
  <div class="page join">
    <transition name="fade">
      <div v-if="btnVisible" class="join-btn-container">
        <button class="join-btn" @click="handleJoin">
          I Am Here
        </button>
        <div class="lds-circle" v-if="loading">
          <div></div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="msgVisible" class="join-success-msg">
        You have added a light to the ground
      </div>
    </transition>

    <transition name="fade">
      <div v-if="formVisible" @submit="handleMsgUpdate" class="join-form">
        <label>
          Share your ground (Optional)
          <textarea
            style="display:block;width:100%;"
            v-model="fields.text"
            placeholder="Your words or intention here "
          ></textarea>
        </label>

        <label>
          Tag (Optional)
          <input type="text" v-model="fields.tag" />
        </label>

        <label>
          Attach an image (Optional)
          <br />
          <small>Size limit {{ sizeLimit }}</small>

          <input
            type="file"
            accept="image/png, image/jpeg"
            ref="imageUpload"
            @change="handleFileChange('image', 'imageUpload')"
          />
        </label>

        <div class="audio-toggle">
          <div>Add an audio file (Optional)</div>

          <div class="toggle-btns">
            <button
              @click="toggleAudio('record')"
              class="btn"
              :class="audioToggle == 'record' ? 'active' : ''"
            >
              Record
            </button>
            <small>
              Or
            </small>
            <button
              @click="toggleAudio('upload')"
              class="btn"
              :class="audioToggle == 'upload' ? 'active' : ''"
            >
              Upload
            </button>
          </div>
        </div>

        <label v-show="audioToggle == 'upload'" ref="uploadSection">
          <small>Size limit {{ sizeLimit }}</small>

          <input
            type="file"
            accept="audio/mp3, audio/wav, audio/aac, audio/m4a"
            ref="audioUpload"
            @change="handleFileChange('audio', 'audioUpload')"
          />
        </label>

        <div
          v-show="audioToggle == 'record'"
          class="audio-recorder"
          ref="recordSection"
        >
          <div>
            <input
              class="start-recording"
              v-show="!recState.isRecording"
              type="button"
              @click="startRecording"
              value="Start Recording"
            />
            <input
              class="stop-recording"
              type="button"
              v-show="recState.isRecording"
              @click="stopRecording"
              value="Stop"
            />

            <div class="recorded-audio" v-show="recState.audioUrl">
              <small>Preview your recording</small>
              <audio controls :src="recState.audioUrl"></audio>
              <small>
                Please make sure your volume is up to hear the preview
              </small>
              <input
                class="delete-record"
                type="button"
                value="Delete recording"
                @click="clearRecording"
              />
            </div>
          </div>
        </div>

        <input
          @click="handleMsgUpdate"
          v-if="!loading"
          class="submit-btn"
          type="submit"
          :value="enteredData ? 'Update & Join' : 'Skip'"
        />
        <div v-else class="lds-circle">
          <div></div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="thankMsgVisible" class="join-success-msg">
        Thank You <br />
        <small> Returning to the meeting ground</small>
      </div>
    </transition>

    <div v-show="errorTxt" class="error">
      {{ errorTxt }}

      <a @click="handleSkip">Return to ground</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Join",
  data() {
    return {
      btnVisible: true,
      msgVisible: false,
      formVisible: false,
      thankMsgVisible: false,
      sizeLimit: "50mb",
      audioToggle: "",
      recState: {
        recorder: null,
        stream: null,
        isRecording: false,
        recordedChunks: [],
        audioUrl: "",
      },
      fields: {
        text: "",
        tag: "",
      },
      files: {
        image: "",
        audio: "",
      },
      errorTxt: "",
      loading: false,
    };
  },
  computed: {
    enteredData() {
      for (var x in this.fields) if (this.fields[x].trim()) return true;
      for (var y in this.files) if (this.files[y]) return true;
      return false;
    },
  },
  methods: {
    async handleJoin() {
      this.loading = true;
      try {
        await this.$store.dispatch("ground/join", {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      } catch (e) {
        this.errorTxt = "Error while joining ground please try again";
        return;
      } finally {
        this.loading = false;
      }

      this.btnVisible = false;
      await this.pause(1500);

      this.msgVisible = true;
      await this.pause(1500);

      this.msgVisible = false;
      await this.pause(1500);
      this.formVisible = true;
    },
    getFormData() {
      let formData = new FormData();
      for (var x in this.fields) formData.append(x, this.fields[x].trim());
      for (var y in this.files) formData.append(y, this.files[y]);
      return formData;
    },
    async handleMsgUpdate(e) {
      e.preventDefault();
      this.errorTxt = "";
      try {
        this.loading = true;
        for (var f in this.files)
          if (!this.verifyFileSize(this.files[f]))
            throw `Chosen ${f} file is too large, size limit is ${this.sizeLimit}`;

        await this.$store.dispatch("ground/updateJoinMsg", this.getFormData());

        this.loading = false;
        this.formVisible = false;
        await this.pause(1500);

        this.thankMsgVisible = true;
        await this.pause(1500);

        this.thankMsgVisible = false;
        await this.pause(3000);

        this.$router.push({ name: "Ground" });
      } catch (e) {
        this.loading = false;
        this.errorTxt = e;
      }
    },
    async handleSkip() {
      try {
        await this.$store.dispatch("ground/updateJoinMsg", {});
      } catch (e) {
        console.log(e);
      } finally {
        this.$router.push({ name: "Ground" });
      }
    },
    handleFileChange(changedMedia, ref) {
      this.$data.files[changedMedia] = this.$refs[ref].files[0];
    },
    async pause(time) {
      await new Promise((resolve) => setTimeout(resolve, time));
    },
    verifyFileSize(file) {
      if (!file) return true;
      if (file.size > 52428800) return false;
      return true;
    },
    toggleAudio(section) {
      this.audioToggle = section;
      this.files.audio = "";
      if (section == "upload") this.clearRecording();
      if (section == "record") this.$refs.audioUpload.value = "";
      this.$nextTick(() => {
        this.$refs[`${section}Section`].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    },
    startRecording() {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          recorder.start();
          recorder.ondataavailable = this.handleRecorderData;
          recorder.onstop = this.handleRecorderStop;
          this.recState.recorder = recorder;
          this.recState.stream = stream;
          this.recState.isRecording = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    stopRecording() {
      this.recState.recorder.stop();
      this.recState.isRecording = false;
    },
    handleRecorderStop() {
      const blob = new Blob(this.recState.recordedChunks, {
        type: "audio/mpeg",
      });
      this.recState.recordedChunks = [];
      this.recState.stream.getTracks().forEach((t) => t.stop());
      this.recState.audioUrl = window.URL.createObjectURL(blob);
      this.files.audio = new File([blob], "recordedFile.mp3", {
        type: "audio/mpeg",
      });
    },
    handleRecorderData(e) {
      this.recState.recordedChunks.push(e.data);
    },
    clearRecording() {
      this.recState.audioUrl = "";
      this.recState.recordedChunks = [];
      this.recState.recorder = null;
      this.files.audio = "";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/pages/join.scss";
</style>
