import Vue from "vue";
import Vuex from "vuex";
import socket from "./socket";
import ground from "./ground";
import chatOverlay from "./chat-overlay";
import msgOverlay from "./message-overlay";
import sideControls from "./side-controls";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    socket,
    ground,
    msgOverlay,
    sideControls,
  },
});
