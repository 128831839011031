<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Navbar />
    <router-view />
    <div class="development-banner">
      App is work in progress, We Welcome your
      <a href="https://www.meetinggroundprojects.org/contact" target="_blank">
        feedback!
      </a>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/css/base.scss";
</style>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: { Navbar },
};
</script>
