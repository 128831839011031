export default {
  namespaced: true,
  state: {
    filterTags: [],
    filters: {
      hasAudio: false,
      hasImage: false,
    },
  },
  getters: {
    activeTags: (state) => state.filterTags,
    activeFilters: (state) => state.filters,
  },
  mutations: {
    ADD_TAG(state, tag) {
      state.filterTags.push(tag);
    },
    REMOVE_TAG(state, tag) {
      state.filterTags = state.filterTags.filter((t) => t != tag);
    },
    CLEAR_ALL_TAGS(state) {
      state.filterTags = [];
    },
    TOGGLE_FILTER(state, filter) {
      state.filters[filter] = !state.filters[filter];
    },
    RESET_ALL(state) {
      state.filterTags = [];
      for (var filter in state.filters) state.filters[filter] = false;
    },
  },
  actions: {
    addTagFilter({ commit, dispatch }, tag) {
      commit("ADD_TAG", tag);
      dispatch("triggerFetchMsgs");
    },
    removeTagFilter({ commit, dispatch }, tag) {
      commit("REMOVE_TAG", tag);
      dispatch("triggerFetchMsgs");
    },
    tagsClearAll({ commit, dispatch }) {
      commit("CLEAR_ALL_TAGS");
      dispatch("triggerFetchMsgs");
    },
    toggleFilter({ commit, dispatch }, filter) {
      commit("TOGGLE_FILTER", filter);
      dispatch("triggerFetchMsgs");
    },
    triggerFetchMsgs({ commit, dispatch }) {
      commit("ground/CLEAR_DATA", null, { root: true });
      commit("ground/TRIGGER_REDRAW", null, { root: true });
      dispatch("ground/fetchMsgs", null, { root: true });
    },
  },
};
