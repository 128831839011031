import Vue from "vue";
import VueRouter from "vue-router";
import Ground from "../views/Ground.vue";
import Join from "../views/Join.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Ground",
    component: Ground,
  },
  {
    path: "/iamhere",
    name: "Join",
    component: Join,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
