<template>
  <div class="home page">
    <NodeGraph />
    <MessageOverlay v-if="isOpen" />
    <BottomControls />
    <!-- <div class="iamhere-btn" :disabled="loading" @click="loadMore">
      Load more lights
    </div> -->
    <SideControls />
  </div>
</template>

<script>
import NodeGraph from "@/components/NodeGraph.vue";
import MessageOverlay from "@/components/MessageOverlay.vue";
import SideControls from "@/components/SideControls.vue";
import BottomControls from "@/components/BottomControls.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: { NodeGraph, MessageOverlay, SideControls, BottomControls },
  mounted() {
    this.$store.dispatch("socket/initConnection");
  },
  computed: {
    ...mapState("msgOverlay", ["isOpen"]),
    ...mapState("ground", ["loading"]),
  },
  methods: {
    loadMore() {
      this.$store.dispatch("ground/fetchMsgs");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/pages/ground.scss";
@import "@/assets/css/message-overlay.scss";
@import "@/assets/css/side-controls.scss";
</style>
