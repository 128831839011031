import msgsApi from "../api/msgs.api";

export default {
  namespaced: true,
  state: {
    isOpen: false,
    isLoading: false,
    msg: {},
  },
  getters: {},
  mutations: {
    SET_STATE(state, payload) {
      state.isOpen = payload.isOpen;
      state.isLoading = payload.isLoading;
      state.msg = payload.msg;
    },
  },
  actions: {
    async open({ state, commit }, id) {
      commit("SET_STATE", { isOpen: true, isLoading: true, msg: {} });
      try {
        let msg = await msgsApi.getMsg(id);
        commit("SET_STATE", {
          isOpen: state.isOpen,
          isLoading: false,
          msg: msg.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    close({ commit }) {
      commit("SET_STATE", { isOpen: false, isLoading: false, msg: {} });
    },
  },
};
