import httpClient from "./httpClient";

const postMsg = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("/messages", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

const getMsgs = (page, tags, filters) => {
  return new Promise((resolve, reject) => {
    let encodedTags = tags.length ? `&tags[]=${tags.join("&tags[]=")}` : "";
    let encodedFilters = `&hasAudio=${filters.hasAudio}&hasImage=${filters.hasImage}`;
    httpClient
      .get(`/messages?page=${page}${encodedTags}${encodedFilters}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

const getMsg = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/messages/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

const updateMsg = (id, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .patch(`/messages/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export default { postMsg, getMsgs, getMsg, updateMsg };
