<template>
  <div class="node-graph-wrapper">
    <div id="d3-wrapper"></div>
  </div>
</template>

<script>
import * as d3 from "d3";
import "d3-selection-multi";
import { mapState } from "vuex";

export default {
  name: "NodeGraph",
  data() {
    return {
      svg: null,
      nodeGroup: null,
      linkGroup: null,
      simulation: null,
      linkForce: null,
      fillPattern: null,
      height: 360,
      width: 360,
      wrapper: null,
      zoomHandler: null,
      id: 3,
    };
  },
  computed: mapState("ground", {
    nodeData: "nodes",
    linkData: "links",
  }),
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "ground/TRIGGER_REDRAW":
          this.restart();
          break;
        case "ground/TRIGGER_ZOOM_RESET":
          this.resetZoom();
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch("socket/closeConnection");
    this.$store.commit("ground/CLEAR_DATA");
  },
  mounted() {
    this.setupSimulation();
    this.$store.dispatch("ground/fetchMsgs");
  },
  methods: {
    setupSimulation() {
      let { width, height } = d3
        .select("#d3-wrapper")
        .node()
        .getBoundingClientRect();

      this.$store.commit("ground/SET_GRAPH_DIMENSIONS", {
        height,
        width,
      });

      this.svg = d3
        .select("#d3-wrapper")
        .append("svg")
        .attr("id", "graph-svg")
        .attr("width", "100%")
        .attr("height", "100%");

      this.fillPattern = this.svg.append("defs");
      this.fillPattern
        .append("pattern")
        .attrs({
          id: "fillImage",
          patternContentUnits: "objectBoundingBox",
          preserveAspectRatio: "none",
          height: "100%",
          width: "100%",
        })
        .append("image")
        .attrs({
          x: 0,
          y: 0,
          height: 1,
          width: 1,
          "xlink:href": require("@/assets/images/light.png"),
        });

      this.linkGroup = this.svg.append("g").classed("link-group", true);
      this.nodeGroup = this.svg.append("g").classed("node-group", true);

      this.linkForce = d3
        .forceLink()
        .distance(200)
        .id((d) => d.id);

      this.simulation = d3
        .forceSimulation()
        .force("charge", d3.forceManyBody().strength(-1000))
        .force("x", d3.forceX(width / 2))
        .force("y", d3.forceY(height / 2))
        //.nodes(this.nodeData)
        //.force("link", this.linkForce)
        .on("tick", this.tick);

      this.zoomHandler = d3
        .zoom()
        .scaleExtent([-2, 1])
        .on("zoom", this.zoomActions);

      this.zoomHandler(this.svg);
      //this.restart();
    },
    tick() {
      this.nodeGroup
        .selectAll("circle")
        .attr("cx", (d) => d.x)
        .attr("cy", (d) => d.y);

      this.nodeGroup
        .selectAll("foreignObject")
        .attr("x", (d) => d.x - 30)
        .attr("y", (d) => d.y + 50);

      this.linkGroup
        .selectAll("line")
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
    },
    restart() {
      this.redrawNodes();
      this.redrawLinks();

      this.linkForce = this.linkForce.links(this.linkData);

      this.simulation
        .nodes(this.nodeData)
        .force("link", this.linkForce)
        .alpha(1)
        .restart();
    },
    redrawNodes() {
      this.nodeGroup.html("");

      let newBinding = this.nodeGroup
        .selectAll("g")
        .data(this.nodeData, (d) => d.id);

      let newNodes = newBinding.enter().append("g");

      newNodes
        .append("circle")
        .attr("r", 50)
        .attr("id", (d) => `node-${d.id}`)
        .attr("fill", "url(#fillImage)")
        .each((d) => this.removeNodeClass(d.id, "entering-node"))
        .classed("node-base", true)
        .classed("entering-node", (d) => d.id != this.userId)
        .on("mousedown", this.onNodeMouseDown)
        .on("mouseover", this.onNodeHover)
        .on("mouseout", this.onNodeLeave);

      newNodes
        .append("foreignObject")
        .attrs({
          width: "100px",
          height: "20px",
          color: "#fff",
          overflow: "visible",
        })
        .append("xhtml:p")
        .attr("margin", "0px")
        .text((d, i) => (i == 0 ? `${d.country} (most recent)` : d.country));

      newBinding.exit().remove();
    },
    redrawLinks() {
      let newBinding = this.linkGroup.selectAll("line").data(this.linkData);

      newBinding
        .enter()
        .append("line")
        .classed("link", true);

      newBinding.exit().remove();
    },
    onNodeMouseDown(d) {
      this.$store.dispatch("msgOverlay/open", d.id);
    },
    onNodeHover(d) {
      d3.select(`#node-${d.id}`).classed("rotating-node", true);
    },
    onNodeLeave(d) {
      d3.select(`#node-${d.id}`).classed("rotating-node", false);
    },
    zoomActions() {
      this.nodeGroup.attr("transform", d3.event.transform);
      this.linkGroup.attr("transform", d3.event.transform);
    },
    resetZoom() {
      this.svg
        .transition()
        .duration(750)
        .call(this.zoomHandler.transform, d3.zoomIdentity);
    },
    removeNodeClass: function(id, classToRemove) {
      setTimeout(() => {
        d3.select(`#node-${id}`)
          .classed(classToRemove, false)
          .classed("node", true);
      }, 1000);
    },
  },
};
</script>
