export default {
  namespaced: true,
  state: {
    isOpen: false,
    loading: "",
    msgs: [],
    currentMsgsId: "",
    lastSenderId: "",
  },
  getters: {},
  mutations: {
    SET_OVERLAY_STATE(state, payload) {
      state.currentMsgsId = payload.id;
      state.loading = payload.isLoading;
      state.isOpen = payload.isOpen;
      state.msgs = payload.msgs;
    },
    SET_LAST_SENDER_ID(state, id) {
      state.lastSenderId = "" + id;
    },
  },
  actions: {
    openOverlay({ commit, dispatch }, userId) {
      commit("SET_OVERLAY_STATE", {
        isLoading: true,
        id: userId,
        msgs: [],
        isOpen: true,
      });
      let msg = {
        type: "request_msgs",
        id: userId,
      };

      dispatch("socket/sendMsg", msg, { root: true });
    },
    recieveOverlayMsgs({ state, commit }, payload) {
      if (payload.id != state.currentMsgsId) return;
      commit("SET_OVERLAY_STATE", {
        isOpen: state.isOpen,
        isLoading: false,
        msgs: payload.msgs,
        id: state.currentMsgsId,
      });
    },
    closeOverlay({ commit }) {
      commit("SET_OVERLAY_STATE", {
        isLoading: false,
        id: "",
        msgs: [],
        isOpen: false,
      });
    },
    notifyMsgAdded({ commit }, userData) {
      commit("SET_LAST_SENDER_ID", userData.id);
    },
  },
};
