<template>
  <div class="bottom-controls">
    <button
      class="iamhere-btn loadmore-btn"
      :disabled="isLoading"
      @click="loadMore"
    >
      Load more lights
    </button>

    <small>Tap a light to explore</small>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BottomControls",
  computed: {
    ...mapState("ground", ["isLoading"]),
  },
  methods: {
    loadMore() {
      this.$store.dispatch("ground/fetchMsgs");
    },
  },
};
</script>

<style></style>
