const availableSocketActions = {
  user_msg: "ground/msgAdded",
};

export default {
  namespaced: true,
  state: {
    conn: null,
  },
  getters: {
    connOpen: (state) => {
      if (!state.conn) return false;
      let readyState = state.conn.readyState;
      return readyState == 1 ? true : false;
    },
  },
  mutations: {
    SET_CONNECTION(state, conn) {
      state.conn = conn;
    },
  },
  actions: {
    initConnection({ commit, dispatch }) {
      let socket = new WebSocket(process.env.VUE_APP_WS_URI);

      socket.onopen = () => {
        commit("SET_CONNECTION", socket);
      };

      socket.onclose = () => {
        commit("SET_CONNECTION", null);
      };

      socket.onerror = () => {
        commit("SET_CONNECTION", null);
      };

      socket.onmessage = (msg) => {
        dispatch("handleMsg", msg.data);
      };
    },
    handleMsg({ dispatch }, msg) {
      try {
        let parsedMsg = JSON.parse(msg);
        if (
          parsedMsg.type != "error" &&
          parsedMsg.type in availableSocketActions
        )
          dispatch(availableSocketActions[parsedMsg.type], parsedMsg, {
            root: true,
          });
      } catch (e) {
        console.log(e);
      }
    },
    sendMsg({ state }, msgData) {
      state.conn.send(JSON.stringify(msgData));
    },
    closeConnection({ state, commit }) {
      if (!state.conn) return;
      state.conn.close();
      commit("SET_CONNECTION", null);
    },
  },
};
