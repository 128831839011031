<template>
  <div class="navbar">
    <div class="logo-wrapper">
      <a class="navbar-logo" @click="handleLogoNavigation">
        <img src="@/assets/images/logo-white.png" alt="" />
      </a>
      <div class="mobile-text" v-if="currentRoute != 'Join'">
        Take a minute <br />
        Touch the Ground <br />
        Tell us when you are there
      </div>
    </div>

    <div class="desktop-text" v-if="currentRoute != 'Join'">
      Take a minute | Touch the Ground | Tell us when you are there
    </div>

    <div class="navbar-links">
      <button
        v-if="currentRoute != 'Join'"
        class="iamhere-btn"
        @click="goToJoin"
      >
        I Am Here
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToJoin() {
      this.$store.commit("sideControls/RESET_ALL");
      this.$router.push({ name: "Join" });
    },
    handleLogoNavigation() {
      if (this.currentRoute == "Ground")
        window.open("https://www.meetinggroundprojects.org/");
      else this.$router.push({ name: "Ground" });
    },
  },
};
</script>

<style></style>
