import msgsApi from "../api/msgs.api";
import router from "../router";

export default {
  namespaced: true,
  state: {
    nodes: [],
    links: [],
    initMsg: {},
    width: 0,
    height: 0,
    currentPage: 0,
    isLoading: false,
  },
  getters: {
    lastNode: (state) => state.nodes[state.nodes.length - 1],
    graphHasNodes: (state) => state.nodes.length,
  },
  mutations: {
    ADD_NODE(state, payload) {
      //if (payload._id) payload.id = payload._id;
      if (payload.toFront) state.nodes.unshift(payload);
      else state.nodes.push(payload);
    },
    ADD_LINK(state, payload) {
      state.links.push(payload);
    },
    TRIGGER_REDRAW() {},
    TRIGGER_ZOOM_RESET() {},
    SET_INIT_MSG(state, payload) {
      state.initMsg = payload;
    },
    INCREMENT_PAGE(state) {
      state.currentPage = state.currentPage + 1;
    },
    CLEAR_DATA(state) {
      state.nodes = [];
      state.links = [];
      state.currentPage = 0;
    },
    TOGGLE_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_GRAPH_DIMENSIONS(state, payload) {
      state.width = payload.width;
      state.height = payload.width;
    },
  },
  actions: {
    //For realtime msgs
    msgAdded({ state, commit }, msgData) {
      let { avgX, avgY } = findAveragePos(state.nodes);
      msgData.x = avgX;
      msgData.y = avgY;
      msgData.toFront = true;
      commit("ADD_NODE", msgData);

      if (state.nodes.length > 1)
        commit("ADD_LINK", {
          source: msgData.id,
          target: state.nodes[1].id,
        });

      commit("TRIGGER_REDRAW");
    },
    async join({ commit }, data) {
      if (!data) return;
      try {
        let res = await msgsApi.postMsg(data);
        commit("SET_INIT_MSG", res.data);
      } catch (e) {
        throw "Request failed";
      }
    },
    async updateJoinMsg({ state }, data) {
      try {
        await msgsApi.updateMsg(state.initMsg._id, data);
      } catch (e) {
        throw e.response.status == 413
          ? "Files are too large"
          : "An error occured";
      }
    },
    async fetchMsgs({ state, commit, getters, dispatch, rootGetters }) {
      if (state.isLoading) return;
      commit("TOGGLE_LOADING", true);
      try {
        let tags = rootGetters["sideControls/activeTags"];
        let filters = rootGetters["sideControls/activeFilters"];
        let res = await msgsApi.getMsgs(state.currentPage, tags, filters);
        let msgs = res.data;
        if (msgs.length) commit("INCREMENT_PAGE");
        else return;

        if (getters.graphHasNodes) {
          await dispatch("addToGraph", msgs);
        } else {
          dispatch("initGraph", msgs);
        }
      } catch (e) {
        console.log(e);
      } finally {
        commit("TOGGLE_LOADING", false);
      }
    },
    initGraph({ commit, state }, msgs) {
      if (msgs.length > 1)
        for (let i = msgs.length - 1; i > 0; i--)
          commit("ADD_LINK", {
            source: msgs[i - 1].id,
            target: msgs[i].id,
          });

      msgs[0].fx = state.width / 2;
      msgs[0].fy = 100;
      for (let msg of msgs) {
        msg.x = 0;
        msg.y = 0;
        commit("ADD_NODE", msg);
      }

      commit("TRIGGER_REDRAW");
      commit("TRIGGER_ZOOM_RESET");
    },
    async addToGraph({ commit, getters }, msgs) {
      for (var msg of msgs) {
        msg.x = getters.lastNode.x + Math.random() * 100;
        msg.y = getters.lastNode.y + Math.random() * 100;
        commit("ADD_LINK", { source: msg.id, target: getters.lastNode.id });
        commit("ADD_NODE", msg);
        commit("TRIGGER_REDRAW");
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    },
  },
};

function findAveragePos(nodes) {
  let totalX = 0,
    totalY = 0,
    countX = 0,
    countY = 0,
    avgX = 0,
    avgY = 0;
  for (let i = 0; i < nodes.length - 1; i++) {
    if (nodes[i].x) {
      totalX += nodes[i].x;
      countX++;
    }
    if (nodes[i].y) {
      totalY += nodes[i].y;
      countY++;
    }
  }
  if (countX) avgX = totalX / countX;
  if (countY) avgY = totalY / countY;
  return { avgX, avgY };
}
