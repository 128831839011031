<template>
  <div class="overlay msg-overlay" @click.self="closeOverlay">
    <div class="msg-overlay-content">
      <div class="close-btn" @click="closeOverlay">
        &#10006;
      </div>

      <div v-if="isLoading" class="lds-dual-ring"></div>
      <div v-else>
        <div class="overlay-section message-tag" v-show="msg.tag">
          {{ msg.tag }}
        </div>

        <div class="overlay-section date">
          {{ senderTime }} {{ timezone }}
          <br />
          <br />
          {{ myTime }}
        </div>

        <div class="overlay-section">
          {{ msg.text ? msg.text : "Here" }}
        </div>

        <div v-if="msg.hasMedia" class="message-media overlay-section">
          <div v-if="msg.img" class="img-wrapper overlay-section">
            <img v-if="msg.img" :src="msg.img" alt="" />
          </div>

          <div v-if="msg.audio" class="audio-wrapper overlay-section">
            <audio v-if="msg.audio" controls>
              <source :src="msg.audio" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MessageOverlay",
  data() {
    return {
      dateOptions: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    };
  },
  computed: {
    senderTime() {
      if (this.msg.timezone == "default" || !this.msg.timezone)
        return "Message timezone was not specified";
      if (Intl.DateTimeFormat().resolvedOptions().timeZone == this.msg.timezone)
        return "This message was left in your timezone";
      return new Date(this.msg.createdAt).toLocaleString(
        {},
        { ...this.dateOptions, timeZone: this.msg.timezone }
      );
    },
    myTime() {
      let time = new Date(this.msg.createdAt).toLocaleString(
        {},
        this.dateOptions
      );
      return `${time} (your timezone)`;
    },
    timezone() {
      if (!this.msg.timezone || this.msg.timezone == "default") return "";
      if (Intl.DateTimeFormat().resolvedOptions().timeZone == this.msg.timezone)
        return "";
      return `(${this.msg.timezone})`;
    },
    ...mapState("msgOverlay", ["msg", "isLoading"]),
  },
  methods: {
    closeOverlay() {
      this.$store.dispatch("msgOverlay/close");
    },
  },
};
</script>

<style></style>
